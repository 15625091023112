// import logo from "./logo.svg";
import "./css/App.css";

// import { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Header from "./component/Header";
import Notfound from "./component/Notfound";

import Home from "./component/Home";
import Introduce from "./component/Introduce";
import Post from "./component/Post";
import PostEdit from "./component/PostEdit";
import Portfolio from "./component/Portfolio";
import PostList from "./component/PostList";
import PortfolioList from "./component/PortfolioList";
import Login from "./component/Login";

function App() {
  if ("admin" in sessionStorage) {
    console.log("admin 존재");
  } else {
    console.log("admin 미존재. admin 초기화.");
    window.sessionStorage.setItem("admin", "");
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Header />}>
          <Route index element={<Home />} />
          <Route path="/introduce" element={<Introduce />} />
          <Route path="/posts/:id" element={<PostList />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/post/edit" element={<PostEdit />} />
          <Route path="/post/:id/edit" element={<PostEdit />} />

          <Route path="/portfolio" element={<PortfolioList />}>
            <Route path=":id" element={<Portfolio />} />
          </Route>

          <Route path="*" element={<Notfound />} />

          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
