import { Box, Grid, Typography } from "@mui/material";

const Home = () => {
  return (
    <>
      <div id="content">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "rgba(178, 144, 121, 0.3)" }}
        >
          <Grid item xs={12}>
            <Box></Box>
            <Typography variant="h1" sx={{ textAlign: "center" }}>
              사이트 소개
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box></Box>
            <Typography variant="h1" sx={{ textAlign: "center" }}>
              업데이트 내역
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;
