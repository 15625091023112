import axios from "axios";

export function selectPostList() {
  // const axios = require("axios");
  // const apiURL = process.env.REACT_APP_APIURL;
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: apiURL + "/posts",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function selectDetailPost(postID) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: apiURL + "/post",

      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      params: { id: postID },
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function insertPost(params) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: apiURL + "/post",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        title: params.title,
        parentnode: params.parentnode,
        category: params.category,
        content: params.content,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePost(params) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: apiURL + "/post",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        id: params.id,
        title: params.title,
        parentnode: params.parentnode,
        category: params.category,
        content: params.content,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletePost(postID) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url: apiURL + "/post",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        id: postID,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadImage(blob) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: apiURL + "/post/image",

      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        blob,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// module.exports = {
//   selectDetailPost,
//   selectPostList,
//   insertPost,
//   deletePost,
//   updatePost,
// };
