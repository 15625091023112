import { Link, useNavigate, useParams } from "react-router-dom";
import { selectPostList } from "../service/PostApiList";
import { useEffect, useState } from "react";
import Progress from "./Progress";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  CardActionArea,
  CardMedia,
  Divider,
  Grid,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PostList = () => {
  // const { admin } = props;
  const [arrPostData, setArrPostData] = useState([]);
  const [prevCategories, setPrevCategories] = useState(null);
  const [progress, setProgress] = useState(true);
  const params = useParams().id;
  const postID = Number(params);
  const admin = sessionStorage.getItem("admin");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("데이터 로딩 중..");
    fn();
  }, []);

  useEffect(() => {
    fn2();
  }, [postID, arrPostData]);

  const fn = () => {
    selectPostList().then((res) => {
      // const arrTemp = JSON.parse(res.body);
      // console.log(res.data.rows);
      const sortedData = res.data.rows.slice().sort((a, b) => a.id - b.id);
      setArrPostData(sortedData);
      setProgress(false);
    });
  };

  const fn2 = () => {
    let targetData;
    let prevPostID = postID;
    let count = 0;
    let prevCategoriesTemp = [];

    if (arrPostData.length > 0) {
      do {
        targetData = arrPostData.find((v) => {
          if (v.id === prevPostID) return true;
        });

        prevCategoriesTemp.splice(0, 0, [targetData.id, targetData.title]);

        prevPostID = targetData.parentnode;

        count = count + 1;

        if (targetData.id === 1) {
          break;
        }
      } while ((targetData.parentnode !== 0) & (count < 10));
    }

    setPrevCategories(
      prevCategoriesTemp.map((v, idx) =>
        postID === v[0] ? (
          <>
            <Typography key={idx}>{v[1]}</Typography>
          </>
        ) : (
          <>
            <Link underline="hover" to={"/posts/" + v[0]} key={idx}>
              {v[1]}
            </Link>
          </>
        )
      )
    );
  };

  return (
    <>
      <Progress progress={progress} />
      <div id="content">
        <Box sx={{ p: 2, bgcolor: "rgba(178, 144, 121, 0.3)" }}>
          <Box className="_postToolMenu">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {prevCategories}
            </Breadcrumbs>
            {typeof admin == "undefined" || admin == null || admin == "" ? (
              ""
            ) : (
              <>
                {/* <button className="_createCategory">카테고리 생성</button> */}
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    component={Link}
                    to="/post/edit"
                    state={{ id: null, parentnode: postID, isNew: true }}
                    variant="contained"
                  >
                    <Typography variant="body2" sx={{ color: "white" }}>
                      게시글 / 카테고리 생성
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {arrPostData.filter((v) => {
            if (v.parentnode === postID) return true;
          }).length === 0 ? (
            <Box>하위 카테고리/게시글이 존재하지 않습니다.</Box>
          ) : (
            <Grid container spacing={2}>
              {arrPostData
                .filter((v) => {
                  if (v.parentnode === postID) return true;
                })
                .map((v, idx) => (
                  <>
                    <Grid item key={idx} md={6} lg={3}>
                      <Card sx={{ bgcolor: "#f6f5ec" }}>
                        <CardActionArea
                          LinkComponent={Link}
                          to={
                            "/post" +
                            (v.category === "P" ? "" : "s") +
                            "/" +
                            v.id
                          }
                        >
                          <CardHeader
                            action={
                              typeof admin === "undefined" ||
                              admin === null ||
                              admin === "" ? (
                                ""
                              ) : (
                                <IconButton
                                  aria-label="settings"
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    e.preventDefault();
                                    navigate("/post/edit", {
                                      state: {
                                        id: v.id,
                                        parentnode: v.parentnode,
                                        isNew: false,
                                      },
                                    });
                                  }}
                                >
                                  <SettingsOutlinedIcon />
                                </IconButton>
                              )
                            }
                            title={
                              <Typography variant="h5">{v.title}</Typography>
                            }
                            subheader={new Date(v.regdate).toLocaleDateString()}
                            titleTypographyProps={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          />
                          <Divider />
                          {/* <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              {v.content}
                            </Typography>
                          </CardContent> */}
                          {v.category === "C" ? (
                            <CardMedia
                              component="img"
                              image={
                                process.env.PUBLIC_URL + "/folder_pixabay_4.png"
                              }
                              alt={v.title + " 대표 사진"}
                              sx={{
                                // p: 2,
                                objectFit: "contain",
                                // maxHeight: "100%",
                                // maxWidth: "100%",
                                height: "192px",
                              }}
                            />
                          ) : (
                            <CardMedia
                              component="img"
                              image={
                                process.env.PUBLIC_URL + "/post_pixabay.png"
                              }
                              alt={v.title + " 대표 사진."}
                              sx={{
                                // p: 2,
                                objectFit: "contain",
                                // maxHeight: "100%",
                                // maxWidth: "100%",
                                height: "192px",
                              }}
                            />
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
        </Box>
        {/* ._postContentBox end*/}
      </div>
      {/* #content end*/}
    </>
  );
};

export default PostList;
