import axios from "axios";

export function adminLogin(params) {
  // const axios = require("axios");
  // const apiURL = process.env.REACT_APP_APIURL;
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: apiURL + "/login",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        id: params.id,
        password: params.password,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
