import { useEffect, useState } from "react";
import { adminLogin } from "../service/LoginApiList";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({ id: "", password: "" });
  const navigate = useNavigate();
  const admin = window.sessionStorage.getItem("admin");

  {
    /*useEffect(() => {
    console.log(window.sessionStorage.getItem("admin"));
    if (window.sessionStorage.getItem("admin") !== "") {
      alert("정상적인 접근이 아닙니다. 이전 페이지로 이동합니다.");
      navigate(-1);
    }
  }, []);*/
  }

  const onLogin = async (e) => {
    e.preventDefault();

    if (admin !== "" && admin !== "undefined" && admin !== null) {
      alert("정상적인 접근이 아닙니다. 메인 페이지로 이동합니다.");
      navigate("/");
    } else {
      const result = await adminLogin({
        id: formData.id,
        password: formData.password,
      });

      const body = result.data;

      if (body === "success") {
        window.sessionStorage.setItem("admin", "admin");
        alert("로그인 되었습니다.");
        navigate("/");
        window.location.reload();
      } else {
        console.log(result);
        alert("로그인에 실패했습니다. " + result);
      }
    }
  };

  const handleChangeForm = (e) => {
    // 입력 필드의 값이 변경될 때마다 상태 업데이트
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <div id="content" className="_contentLogin">
        <Box
          sx={{
            mt: 8,
            mb: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            관리자 로그인
          </Typography>
          <Box
            component="form"
            onSubmit={onLogin}
            sx={{ mt: 2, backgroundColor: "white", p: 6 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="id"
              type="text"
              placeholder="아이디"
              onChange={handleChangeForm}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              placeholder="비밀번호"
              onChange={handleChangeForm}
            />
            <Button type="submit" fullWidth>
              로그인
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Login;
