import { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { selectDetailPost } from "../service/PostApiList";
import Progress from "./Progress";
import { Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  deleteComment,
  insertComment,
  selectCommentList,
} from "../service/CommentApiList";
import LoadingButton from "@mui/lab/LoadingButton";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

const Post = () => {
  const navigate = useNavigate();
  const params = useParams().id;
  const postID = Number(params);
  const [progress, setProgress] = useState(true);
  const [comment, setComment] = useState({
    postID: postID,
    author: null,
    content: null,
    parentnode: 0,
  });
  const admin = window.sessionStorage.getItem("admin");
  const viewerRef = useRef();
  const [postData, setPostData] = useState({
    id: postID,
    title: "",
    parentnode: null,
    category: "P",
    content: null,
    regdate: null,
  });
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState([]);
  // const [parentNumber, setParentNumber] = useState(0);
  // const [replyClicked, setReplyClicked] = useState(false);

  const initializePage = () => {
    fn1();
    fn2();
    setProgress(false);
  };

  const fn1 = async () => {
    const result = await selectDetailPost(postID);
    const temp = result.data.rows[0];
    const selectedID = temp.id;
    const selectedTitle = temp.title;
    const selectedParentnode = temp.parentnode;
    const selectedCategory = temp.category;
    const selectedContent = temp.content;
    const selectedRegdate = temp.regdate;
    if (
      (selectedID && selectedID === "undefined") ||
      selectedCategory === "C"
    ) {
      alert("정상적인 접근이 아닙니다. 이전 페이지로 이동합니다.");
      navigate(-1);
    }
    setPostData({
      ...postData,
      title: selectedTitle,
      parentnode: selectedParentnode,
      category: selectedCategory,
      content: selectedContent,
      regdate: new Date(selectedRegdate).toLocaleDateString(),
    });
    viewerRef.current.getInstance().setMarkdown(selectedContent);
  };

  const fn2 = async () => {
    const commentResult = await selectCommentList(postID);
    setCommentList(commentResult.data.rows);
    // console.log(commentList);
  };

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    setComment({ ...comment, author: "", content: "", parentnode: 0 });
  }, [commentList]);

  // useEffect(() => {
  //   setComment({ ...comment, parentnode: parentNumber });
  //   console.log(parentNumber);
  // }, [parentNumber]);

  const handleInsertComment = async () => {
    // alert(
    //   "author: " +
    //     comment.author +
    //     "\ncontent: " +
    //     comment.content +
    //     "\npostID: " +
    //     comment.postID
    // );
    setLoading(true);
    await insertComment(comment);
    await fn2();
    setLoading(false);
  };

  const handleDeleteComment = async (e) => {
    // setLoading(!loading);
    await deleteComment(e.target.id);
    await fn2();
    // setLoading(!loading);
    // console.log(e.target.id);
  };

  const handleChangeComment = (e) => {
    const { value, name } = e.target;
    setComment({ ...comment, [name]: value });
  };

  // const handleChangeParent = (e) => {
  //   setParentNumber(e.target.id);
  // };

  // const commentWriter = (
  //   <>
  //     <Grid
  //       container
  //       sx={{
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         p: 2,
  //         mt: 2,
  //       }}
  //       spacing={2}
  //     >
  //       <Grid
  //         item
  //         xs={parentNumber === 0 ? 0 : 1}
  //         sx={{ display: "flex", justifyContent: "left" }}
  //         id={0}
  //         onClick={handleChangeParent}
  //       >
  //         에게 답글
  //       </Grid>
  //       <Grid item xs={parentNumber === 0 ? 2 : 1}>
  //         <TextField
  //           label="작성자"
  //           variant="standard"
  //           name="author"
  //           fullWidth
  //           // rows={2}
  //           // maxRows={3}
  //           placeholder="작성자 입력"
  //           value={comment.author}
  //           onChange={handleChangeComment}
  //         />
  //       </Grid>
  //       <Grid item xs={8}>
  //         <TextField
  //           label="댓글"
  //           name="content"
  //           multiline
  //           fullWidth
  //           rows={1}
  //           placeholder="댓글 입력"
  //           value={comment.content}
  //           onChange={handleChangeComment}
  //         />
  //       </Grid>
  //       <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
  //         <LoadingButton
  //           loading={loading}
  //           loadingIndicator="Sending…"
  //           variant="contained"
  //           size="large"
  //           disabled={
  //             comment.content === "" ||
  //             comment.content === null ||
  //             comment.author === "" ||
  //             comment.author === null
  //           }
  //           onClick={handleInsertComment}
  //         >
  //           댓글 작성
  //         </LoadingButton>
  //       </Grid>
  //     </Grid>
  //   </>
  // );

  // const commentViewer = () => {
  //   return commentList.map((v, idx) => (
  //     <>
  //       <Divider />
  //       <Grid
  //         container
  //         item
  //         xs={12}
  //         justifyContent="center"
  //         alignItems="center"
  //         key={idx}
  //       >
  //         <Grid item xs={2} sx={{ display: "flex", justifyContent: "left" }}>
  //           {v.author}
  //         </Grid>
  //         <Grid item xs={7}>
  //           <Typography>{v.content}</Typography>
  //         </Grid>
  //         <Grid item xs={2} sx={{ display: "flex", justifyContent: "right" }}>
  //           {v.regdate}
  //         </Grid>
  //         <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
  //           {typeof admin === "undefined" || admin === null || admin === "" ? (
  //             ""
  //           ) : (
  //             <Button size="small" onClick={handleDeleteComment}>
  //               댓글 삭제
  //             </Button>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </>
  //   ));
  // };

  return (
    <>
      <Progress progress={progress} />
      <Box id="content" sx={{ bgcolor: "rgba(178, 144, 121, 0.3)", p: 2 }}>
        <div className="_prevPage">
          <Link to={"/posts/" + postData.parentnode}>상위 카테고리로 이동</Link>
        </div>
        <Box>
          <Typography>{postData.title}</Typography>
        </Box>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Viewer initialValue={postData.content || ""} ref={viewerRef} />
        </Paper>
        {typeof admin === "undefined" || admin === null || admin === "" ? (
          ""
        ) : (
          <div className="_postContentUpdate">
            <Link
              to={"/post/edit"}
              state={{
                id: postID,
                parentnode: postData.parentnode,
                isNew: false,
              }}
            >
              게시글 수정
            </Link>
          </div>
        )}
        <Box sx={{ mt: 2, mb: 2, bgcolor: "white" }}>
          {commentList.map((v, idx) => (
            <>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                key={idx}
                sx={{ p: 2 }}
              >
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "left" }}
                >
                  {v.author}
                </Grid>
                <Grid item xs={7}>
                  {/* <Typography id={v.id} onClick={handleChangeParent} > */}
                  <Typography>{v.content}</Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  {new Date(v.regdate).toLocaleDateString()}
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {typeof admin === "undefined" ||
                  admin === null ||
                  admin === "" ? (
                    ""
                  ) : (
                    <Button
                      size="small"
                      id={v.id}
                      onClick={handleDeleteComment}
                    >
                      댓글 삭제
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </>
          ))}
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
            spacing={2}
          >
            {/* <>
              <Grid
                item
                xs={parentNumber === 0 && !replyClicked ? 0 : 1}
                sx={{ display: "flex", justifyContent: "left" }}
                id={0}
                onClick={handleChangeParent}
              >
                에게 답글
              </Grid>
            </> */}
            <Grid item xs={2}>
              <TextField
                label="작성자"
                variant="standard"
                name="author"
                fullWidth
                // rows={2}
                // maxRows={3}
                placeholder="작성자 입력"
                value={comment.author}
                onChange={handleChangeComment}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="댓글"
                name="content"
                multiline
                fullWidth
                rows={1}
                placeholder="댓글 입력"
                value={comment.content}
                onChange={handleChangeComment}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                loading={loading}
                loadingIndicator="Sending…"
                variant="contained"
                size="large"
                disabled={
                  comment.content === "" ||
                  comment.content === null ||
                  comment.author === "" ||
                  comment.author === null
                }
                onClick={handleInsertComment}
              >
                댓글 작성
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Post;
