import axios from "axios";

export function selectCommentList(postID) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: apiURL + "/comment",

      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      params: { postID: postID },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  // id, content, author, parentnode, regdate
}

export function insertComment(params) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: apiURL + "/comment",

      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        author: params.author,
        postID: params.postID,
        content: params.content,
        parentnode: params.parentnode,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateComment(params) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: apiURL + "/comment",

      headers: {
        // "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        id: params.id,
        author: params.author,
        postID: params.postID,
        content: params.content,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteComment(commentID) {
  const apiURL = process.env.REACT_APP_APIURL;

  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url: apiURL + "/comment",

      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + process.env.REACT_APP_API_TOKEN,
        Authorization: process.env.REACT_APP_API_TOKEN_TEST,
      },
      data: {
        id: commentID,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
