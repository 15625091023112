import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/i18n/ko-kr";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  insertPost,
  selectDetailPost,
  updatePost,
  deletePost,
} from "../service/PostApiList";
import Progress from "./Progress";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { uploadImage } from "../service/PostApiList";
import { LoadingButton } from "@mui/lab";

const PostEdit = () => {
  const navigate = useNavigate();
  const admin = sessionStorage.getItem("admin");
  // const { admin } = props;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(true);
  const location = useLocation();
  const prevParentnode = location.state.parentnode;
  const prevID = location.state.id;
  const isNew = location.state.isNew;
  let imageBlobList = [];

  const editorRef = useRef();

  const [formData, setFormData] = useState({
    id: prevID,
    title: "",
    parentnode: prevParentnode,
    category: "P",
    content: null,
    regdate: null,
  });

  const CheckAccess = () => {
    if (!(prevID || prevParentnode)) {
      alert("정상적인 접근이 아닙니다. 이전 페이지로 이동합니다.");
      navigate(-1);
    } else if (typeof admin === "undefined" || admin === null || admin === "") {
      alert("관리자 모드가 아닙니다. 이전 페이지로 이동합니다.");
      navigate(-1);
    }
  };

  // const selectPostData = async (num) => {
  //   return await selectDetailPost(num);
  // };

  const insertPostToAPI = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const { id, title, parentnode, category, content } = formData;
    const result = await insertPost({ title, parentnode, category, content });

    if (category === "C") {
      navigate("/posts/" + parentnode);
    } else if (category === "P") {
      navigate("/post/" + result.data.rows[0].id);
    }
  };

  const updatePostToAPI = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    const { id, title, parentnode, category, content } = formData;
    const result = await updatePost({
      id,
      title,
      parentnode,
      category,
      content,
    });

    if (category === "C") {
      navigate("/posts/" + parentnode);
    } else if (category === "P") {
      navigate("/post/" + prevID);
    }
  };

  const fn1 = async () => {
    CheckAccess();
    if (prevID) {
      const result = await selectDetailPost(prevID);
      const temp = result.data.rows[0];
      const selectedID = temp.id;
      const selectedTitle = temp.title;
      const selectedParentnode = temp.parentnode;
      const selectedCategory = temp.category;
      const selectedContent = temp.content;
      const selectedRegdate = temp.regdate;
      if (selectedCategory === "P") {
        editorRef.current.getInstance().setMarkdown(selectedContent);
      }
      setFormData({
        ...formData,
        title: selectedTitle,
        parentnode: selectedParentnode,
        category: selectedCategory,
        content: selectedContent,
        regdate: new Date(selectedRegdate).toLocaleDateString(),
      });
    }
    setProgress(false);
  };

  useEffect(() => {
    // CheckAdmin();
    fn1();
  }, []);

  // useEffect(() => {
  //   console.log("Form Data:", formData);
  // }, [formData]);

  const handleChangeForm = (e) => {
    // 입력 필드의 값이 변경될 때마다 상태 업데이트
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeEditor = (e) => {
    // 입력 필드의 값이 변경될 때마다 상태 업데이트
    const editorContent = editorRef.current.getInstance().getMarkdown();
    setFormData({
      ...formData,
      content: editorContent,
    });
  };

  const clickDelete = async () => {
    if (
      window.confirm(
        (formData.category === "P"
          ? "게시글을"
          : "이 카테고리를 삭제하면 하위 카테고리/게시글도 모두 삭제됩니다. 그래도") +
          " 삭제 하시겠습니까?"
      )
    ) {
      setLoading(!loading);
      navigate("/posts/" + prevParentnode);
      await deletePost(prevID);
      window.location.reload();
      alert("삭제되었습니다.");
    }
  };

  const uploadPostImage = async (imageList) => {
    imageList.forEach(({ imageUrl, imageName, blob }) => {
      const formImageData = new FormData();
      formImageData.append("file", blob, imageName);
      const result = uploadImage(blob);
      setFormData({
        ...formData,
        content: formData.content.replace(imageUrl, result),
      });
      URL.revokeObjectURL(imageUrl);
    });
  };

  return (
    <>
      <Progress progress={progress} />
      <div id="content">
        <Box sx={{ mt: 3, mb: 3 }}>
          <Link to={"/posts/" + formData.parentnode}>상위 카테고리로 이동</Link>
        </Box>
        <form onSubmit={isNew ? insertPostToAPI : updatePostToAPI}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="title"
                placeholder="게시글/카테고리 제목을 입력해 주세요."
                value={formData.title}
                onChange={handleChangeForm}
                label="제목"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                name="category"
                type="radio"
                value={"C"}
                id="categoryCheck"
                checked={formData.category === "C"}
                onChange={handleChangeForm}
                disabled={!isNew}
              />
              <label htmlFor="categoryCheck">카테고리</label>
              <input
                name="category"
                type="radio"
                value={"P"}
                id="postCheck"
                checked={formData.category === "P"}
                onChange={handleChangeForm}
                disabled={!isNew}
              />
              <label htmlFor="postCheck">게시글</label>
            </Grid>
            {formData.category === "C" ? (
              ""
            ) : (
              <Grid item xs={12}>
                <Editor
                  initialValue={formData.content || ""}
                  previewStyle="vertical"
                  height="600px"
                  initialEditType="wysiwyg"
                  useCommandShortcut={false}
                  language="ko-KR"
                  // i18n={ko.default || ko}
                  ref={editorRef}
                  onChange={handleChangeEditor}
                  hooks={{
                    addImageBlobHook: async (blob, callback) => {
                      // console.log(blob); // File {name: '카레유.png', ... }

                      // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
                      // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...

                      const imageUrl = URL.createObjectURL(blob);
                      const imageName =
                        new Date().getTime() + "_" + admin + "_" + blob.name;

                      blob.name = imageName;

                      imageBlobList.push({ imageUrl, imageName, blob });
                      console.log(imageBlobList);

                      // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
                      // content 예시 : ![1709460537427_admin_포트폴리오 배색.png](blob:http://localhost:3000/cc7acfd5-12f7-46a1-b92f-53cee5133e42)
                      callback(imageUrl, imageName);

                      return false;
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Stack spacing={2} direction="row">
                {isNew ? (
                  ""
                ) : (
                  <LoadingButton
                    loading={loading}
                    loadingIndicator="Sending…"
                    variant="outlined"
                    type="button"
                    onClick={clickDelete}
                  >
                    {formData.category === "P" ? "게시글" : "카테고리"} 삭제
                  </LoadingButton>
                )}
                <LoadingButton
                  loading={loading}
                  loadingIndicator="Sending…"
                  variant="outlined"
                  type="submit"
                  disabled={
                    (formData.category === "P" &&
                      (formData.content === "" || formData.content === null)) ||
                    formData.title === "" ||
                    formData.title === null
                  }
                >
                  작성 완료
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default PostEdit;
