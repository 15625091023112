import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

const Header = () => {
  const [admin, setAdmin] = useState(window.sessionStorage.getItem("admin"));
  const navigate = useNavigate();
  const [pageScroll, setPageScroll] = useState(0);

  const isAdmin = (state) => {
    if (typeof state === "undefined" || state === null || state === "") {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setPageScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // 메모리 누수 방지를 위해 add 후 remove로 삭제. 이벤트는 그대로 발생함.
    };
  }, []);

  return (
    <>
      <AppBar
        position={pageScroll === 0 ? "" : "fixed"}
        sx={{
          boxShadow: 0,
          backgroundImage: "none",
          bgcolor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "row",
                ml: "-18px",
                px: 0,
              }}
            >
              <MenuItem component={Link} to="/">
                <Avatar
                  src={process.env.PUBLIC_URL + "/profile_picture.jpg"}
                  alt="Logo image"
                />
              </MenuItem>
              <MenuItem component={Link} to="/introduce">
                <Typography variant="h6">자기소개</Typography>
              </MenuItem>
              <MenuItem component={Link} to="/posts/1">
                <Typography variant="h6">Blog</Typography>
              </MenuItem>
              <MenuItem component={Link} to="/portfolio">
                <Typography variant="h6">포트폴리오</Typography>
              </MenuItem>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {isAdmin(admin) ? (
                <Button
                  onClick={() => {
                    alert("로그아웃 되었습니다.");
                    window.sessionStorage.setItem("admin", "");
                    setAdmin("");
                    // redirect("/");
                    navigate("/");
                    // window.location.reload();
                  }}
                >
                  로그아웃
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
};

export default Header;
