import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const Introduce = () => {
  return (
    <div id="content">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ bgcolor: "rgba(178, 144, 121, 0.3)" }}
      >
        <Grid item xs={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={process.env.PUBLIC_URL + "/profile_picture.jpg"}
              alt="Profile pic"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper} sx={{ ml: -5 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>권승구</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>학력</TableCell>
                  <TableCell>
                    잠실고등학교 졸업(2015.02)
                    <br />
                    강남대학교 졸업(2020.02)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>경력</TableCell>
                  <TableCell>
                    제로바코코리아(2020.05 ~ 2022.08) (RPA 개발자)
                    <br />
                    투비웨이(2022.08 ~ 2023.11) (RPA 개발자)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>링크</TableCell>
                  <TableCell>
                    <Typography
                      sx={{ color: "blue" }}
                      component={Link}
                      to="https://github.com/Seunggu-K?tab=repositories"
                      target="_blank"
                    >
                      Github
                    </Typography>
                    <b> / </b>
                    <Typography
                      sx={{ color: "blue" }}
                      component={Link}
                      to="https://kaggle.com/moonlight"
                      target="_blank"
                    >
                      Kaggle
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>교육이력</TableCell>
                  <TableCell>
                    파이썬과 오픈소스를 활용한 AI&딥러닝(2020.10 ~ 2020.11)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>자격증</TableCell>
                  <TableCell>PCCE 파이썬(2023.10)</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h1">인트로의 인트로</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h1">본문1</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h1">본문2</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
            <Typography variant="h1">마무리</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Introduce;
